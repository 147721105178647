import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BACKEND_URL from './config';

const ResetPasswordForm = () => {
    const { token } = useParams(); // Extract the token from the URL
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate password and confirm password
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            // Make a POST request to the backend
            const response = await axios.post(`${BACKEND_URL}/api/reset-password/${token}`, {
                new_password: newPassword,
            });

            if (response.data.success) {
                setMessage('Password reset successfully. Redirecting to login...');
                setError('');
                setTimeout(() => navigate('/tms/login'), 3000); // Redirect to login after 3 seconds
            } else {
                setError(response.data.message || 'Failed to reset password');
            }
        } catch (err) {
            console.error(err);
            setError(err.response?.data?.message || 'An error occurred while resetting the password');
        }
    };

    return (
        <div className="container">
            <h2 className="text-center mt-5">Reset Your Password</h2>
            <form onSubmit={handleSubmit} className="mt-4">
                <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        className="form-control"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        className="form-control"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <div className="alert alert-danger">{error}</div>}
                {message && <div className="alert alert-success">{message}</div>}
                <button type="submit" className="btn btn-primary w-100">Reset Password</button>
            </form>
        </div>
    );
};

export default ResetPasswordForm;
