// useIdleTimeout.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useIdleTimeout = (timeout = 600000) => { // default to 10 minutes
    const navigate = useNavigate();

    useEffect(() => {
        let timer;
        const resetTimer = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                localStorage.removeItem('token');
                navigate('/tms/login');
            }, timeout);
        };

        window.addEventListener('load', resetTimer);
        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('mousedown', resetTimer);
        window.addEventListener('click', resetTimer);
        window.addEventListener('scroll', resetTimer);
        window.addEventListener('keypress', resetTimer);

        resetTimer();

        return () => {
            clearTimeout(timer);
            window.removeEventListener('load', resetTimer);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('mousedown', resetTimer);
            window.removeEventListener('click', resetTimer);
            window.removeEventListener('scroll', resetTimer);
            window.removeEventListener('keypress', resetTimer);
        };
    }, [navigate, timeout]);
};

export default useIdleTimeout;
