// src/components/Contact.js
import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import contactImage from '../assets/images/contact.jpg'; // Import the image
import './Contact.css'; // Import the CSS file

const Contact = () => {
  return (
    <div className="contact-section" style={{ backgroundImage: `url(${contactImage})` }}>
      <div className="overlay">
        <Container id="contact" className="py-5 fullscreen-section">
          <Row className="align-items-center justify-content-center">
            <Col md={12}> {/* Increase the column width */}
              <h2>Contact Us</h2>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mt-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter your email" />
                </Form.Group>

                <Form.Group controlId="formMessage" className="mt-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Enter your message" />
                </Form.Group>

                <Button variant="primary" type="submit" className="mt-3">
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Contact;
