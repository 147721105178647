// src/components/Navbar.js
import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './Navbar.css'; // Import the updated CSS
import logo from '../assets/images/logo.png'; // Import the Freight Ops logo

const NavigationBar = () => {
  return (
    <Navbar bg="light" variant="light" expand="lg" fixed="top" className="navbar-custom">
      <Navbar.Brand href="#home">
        <img
          src={logo}
          alt="Freight Ops TMS Logo"
          className="navbar-logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {/* Left-aligned links */}
        <Nav className="me-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#services">Services</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>

        {/* Right-aligned TMS App link */}
        <Nav className="ms-auto">
          <Nav.Link
            href="/tms/login"
            target="_blank"
            rel="noopener noreferrer"
            className="tms-app-link"
          >
            TMS App
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
