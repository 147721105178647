// src/components/Services.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import serviceImage from '../assets/images/service.jpg'; // Import the image

const Services = () => {
  const sectionStyle = {
    backgroundImage: `url(${serviceImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: 'white',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0',
  };

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    border: 'none',
  };

  return (
    <div style={sectionStyle} id="services">
      <Container>
        <Row>
          <Col className="text-center">
            <h2>Our Services</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4}>
            <Card className="mb-4" style={cardStyle}>
              <Card.Body>
                <Card.Title>Freight Ops TMS</Card.Title>
                <Card.Text>
                  Freight Ops TMS provides a lightweight and flexible solution to your transportation challenges.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4" style={cardStyle}>
              <Card.Body>
                <Card.Title>Business Analytics</Card.Title>
                <Card.Text>
                  We provides end to end solutions for all your data needs. From setting up all your data connections to creating easy to use dashboards and reporting, we can provide a wide range of data analytics services that fit your company's needs.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="mb-4" style={cardStyle}>
              <Card.Body>
                <Card.Title>Integrations and Development</Card.Title>
                <Card.Text>
                  Providing custom integration and implementation services. Netsuite integrations and development, API management, Cloud setup/maintenance, data migration, and custom app development.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
