// src/components/About.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import trucksImage from '../assets/images/about.jpg'; // Adjust the path as necessary
import './About.css'; // Import the CSS file

const About = () => {
  return (
    <div className="about-section">
      <Container id="about" className="py-5 fullscreen-section">
        <Row className="align-items-center">
          <Col md={6}>
            <h2>About Us</h2>
            <p>
              We dedicate ourselves to providing efficient solutions for your company's needs. Our team consists of leaders in their field for data analytics, software development, and product integrations across multiple industries.
            </p>
          </Col>
          <Col md={6}>
            <img src={trucksImage} alt="Trucks" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
