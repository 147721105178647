import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import './styles.css';
import { AuthContext } from './AuthContext';
import logo from './assets/logo.png';
import { FaHome, FaTruck, FaTruckLoading, FaRegFileAlt, FaEnvelope, FaSignOutAlt } from 'react-icons/fa';

const Sidebar = () => {
    const { setLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = async () => {
        await fetch('/api/logout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        localStorage.removeItem('token');
        setLoggedIn(false);
        navigate('/tms/login');
    };

    return (
        <div className="sidebar">
            <div className="freight-ops-tms">
                <img src={logo} alt="Freight Ops TMS Logo" className="sidebar-logo spaced-logo" />
            </div>
            <Nav className="flex-column">
                <Nav.Item>
                    <Nav.Link as={Link} to="/tms/home">
                        <FaHome className="icon-spacing" /> Home
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/tms/quotes">
                        <FaTruck className="icon-spacing" /> Quotes
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/tms/spot-quotes">
                        <FaTruckLoading className="icon-spacing" /> Spot Quotes
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/tms/booked-shipments">
                        <FaRegFileAlt className="icon-spacing" /> Booked Shipments
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/tms/contact">
                        <FaEnvelope className="icon-spacing" /> Contact
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link onClick={handleLogout}>
                        <FaSignOutAlt className="icon-spacing" /> Logout
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
};

export default Sidebar;
