import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './tableStyles.css';
import CustomAlert from './CustomAlert';
import BACKEND_URL from './config'; // Ensure you have this config for the backend URL
import { FaSearch, FaTimes } from "react-icons/fa";

const BookedShipmentsTable = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]); // For holding filtered data
    const [columnNames, setColumnNames] = useState([]);
    const [error, setError] = useState('');
    const [alert, setAlert] = useState({ show: false, title: '', message: '' });
    const [searchQuery, setSearchQuery] = useState(''); // Search query state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/api/booked-shipments`, {
                    withCredentials: true, // Ensures the session cookie is sent
                });
                setColumnNames(response.data.column_names);
                setData(response.data.data);
                setFilteredData(response.data.data); // Set initial filtered data to full data
            } catch (error) {
                setError('Error fetching data');
                console.error('Error fetching data:', error);
                handleShowAlert('Error', 'Failed to fetch data'); // Show alert here
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(data); // Reset to full data if no search query
        } else {
            setFilteredData(
                data.filter(row =>
                    row.some(cell => cell && cell.toString().toLowerCase().includes(searchQuery.toLowerCase()))
                )
            );
        }
    }, [searchQuery, data]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value); // Update search query state
    };

    const handleShowAlert = (title, message) => {
        setAlert({ show: true, title, message });
    };

    const handleCloseAlert = () => {
        setAlert({ show: false, title: '', message: '' });
    };

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="table-container">
            <h2>Booked Shipments</h2>

            <div className="search-bar-wrapper">
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                    />
                    {searchQuery ? (
                        <FaTimes className="clear-icon" onClick={() => setSearchQuery('')} />
                    ) : (
                        <FaSearch className="search-icon" />
                    )}
                </div>
            </div>

            <div className="table-content-container"> {/* Matching the QuotesTable wrapper */}
                <table>
                    <thead>
                        <tr>
                            {columnNames.map((col, index) => (
                                <th key={index}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex}>{cell != null ? cell : ''}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <CustomAlert
                show={alert.show}
                handleClose={handleCloseAlert}
                title={alert.title}
                message={alert.message}
            />
        </div>
    );
};

export default BookedShipmentsTable;
