import React from 'react';
import NavigationBar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LandingPage.css'; // Use this CSS specifically for LandingPage

const LandingPage = () => {
  return (
    <div className="landing-page-container">
      <NavigationBar />
      <section id="home" className="landing-section">
        <Hero />
      </section>
      <section id="about" className="landing-section">
        <About />
      </section>
      <section id="services" className="landing-section">
        <Services />
      </section>
      <section id="contact" className="landing-section">
        <Contact />
      </section>
    </div>
  );
};

export default LandingPage;
