import React, { useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import axios from 'axios';
import BACKEND_URL from './config';

const ResetPasswordRequestForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BACKEND_URL}/api/request-password-reset`, { email });
            if (response.data.success) {
                setMessage('Password reset link sent. Please check your email.');
            } else {
                setError('Failed to send password reset link.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
        }
    };

    return (
        <Container className="reset-password-container">
            <h2>Reset Password</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </Form.Group>
                {message && <div className="success-message">{message}</div>}
                {error && <div className="error-message">{error}</div>}
                <Button variant="primary" type="submit" className="submit-button">
                    Send Reset Link
                </Button>
            </Form>
        </Container>
    );
};

export default ResetPasswordRequestForm;
