import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import Layout from './Layout';
import Home from './Home';
import QuotesTable from './QuotesTable';
import SpotQuotesForm from './SpotQuotesForm';
import Contact from './Contact';
import LoginForm from './LoginForm';
import LandingPage from './LandingPage';
import ResetPasswordRequestForm from './ResetPasswordRequestForm'; // Import the ResetPasswordRequestForm component
import ResetPasswordForm from './ResetPasswordForm'; // Import the ResetPasswordForm component
import { AuthProvider, AuthContext } from './AuthContext';
import IdleTimeoutWrapper from './IdleTimeoutWrapper';
import BookedShipmentsTable from './BookedShipmentsTable';

const RedirectToHome = () => {
    const { loggedIn } = useContext(AuthContext);
    return loggedIn ? <Navigate to="/tms/home" replace /> : <Navigate to="/tms/login" replace />;
};

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <IdleTimeoutWrapper timeout={600000}> {/* 10 minutes */}
                    <Routes>
                        {/* Landing Page */}
                        <Route path="/" element={<LandingPage />} />

                        {/* Redirect /tms to /tms/home if logged in */}
                        <Route path="/tms" element={<RedirectToHome />} />

                        {/* Login Page */}
                        <Route path="/tms/login" element={<LoginForm />} />

                        {/* Password Reset Request Pages */}
                        <Route path="/tms/reset-password" element={<ResetPasswordRequestForm />} />
                        <Route path="/tms/reset-password/:token" element={<ResetPasswordForm />} />

                        {/* Layout Pages */}
                        <Route
                            path="/tms/*"
                            element={
                                <Layout>
                                    <Routes>
                                        <Route path="home" element={<Home />} />
                                        <Route path="quotes" element={<QuotesTable />} />
                                        <Route path="spot-quotes" element={<SpotQuotesForm />} />
                                        <Route path="booked-shipments" element={<BookedShipmentsTable />} />
                                        <Route path="contact" element={<Contact />} />
                                        {/* Catch-all route */}
                                        <Route path="*" element={<Navigate to="/tms/home" replace />} />
                                    </Routes>
                                </Layout>
                            }
                        />

                        {/* Catch-all route for unmatched paths */}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </IdleTimeoutWrapper>
            </Router>
        </AuthProvider>
    );
};

export default App;
