// src/config.js
const BACKEND_URL =  'https://freightopstms.com/tms'
//   'https://freightopstms.com/tms'
//   'http://152.67.229.27:5001'
//   'https://freightopstms.com' 
//   'http://152.67.229.27:5000'
//   'http://localhost:5000'  
//   'http://localhost:5000/tms'

export default BACKEND_URL;
