import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './tableStyles.css';
import BookShipmentDialog from './BookShipmentDialog';
import { useNavigate } from 'react-router-dom';
import CustomAlert from './CustomAlert';
import BACKEND_URL from './config';
import { FaSearch, FaTimes } from "react-icons/fa";
import LoadingDialog from './LoadingDialog'; // Import LoadingDialog component

const QuotesTable = () => {
    const [data, setData] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [selectedQuote, setSelectedQuote] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [error, setError] = useState('');
    const [alert, setAlert] = useState({ show: false, title: '', message: '' });
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true); // Show loading dialog while fetching
                const response = await axios.get(`${BACKEND_URL}/api/quotes`, {
                    withCredentials: true,
                });
                setColumnNames(response.data.column_names);
                setData(response.data.data);
                setFilteredData(response.data.data);
                setLoading(false); // Hide loading dialog after data loads
            } catch (error) {
                setLoading(false); // Hide loading dialog on error
                if (error.response && error.response.status === 401) {
                    navigate('/tms/login');
                } else {
                    setError('Error fetching data');
                }
            }
        };

        fetchData();
    }, [navigate]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredData(data);
        } else {
            setFilteredData(
                data.filter(row =>
                    row.some(cell => cell && cell.toString().toLowerCase().includes(searchQuery.toLowerCase()))
                )
            );
        }
    }, [searchQuery, data]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleBookShipment = (quote) => {
        setSelectedQuote(quote);
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleShowAlert = (title, message) => {
        setAlert({ show: true, title, message });
    };

    const handleCloseAlert = () => {
        setAlert({ show: false, title: '', message: '' });
    };

    const removeRowsWithSameSalesOrder = (salesOrder) => {
        setData((prevData) => prevData.filter(row => row[0] !== salesOrder));
    };

    const getRowClass = (index, salesOrder) => {
        if (index === 0) return 'row-odd';
        if (data[index - 1] && data[index - 1].length > 0) {
            const prevSalesOrder = data[index - 1][0];
            return prevSalesOrder === salesOrder 
                ? data[index - 1].className 
                : (data[index - 1].className === 'row-odd' ? 'row-even' : 'row-odd');
        }
        return 'row-odd';
    };

    if (loading) {
        return <LoadingDialog />; // Show the loading dialog if loading
    }

    if (error) {
        return <div className="error-message">{error}</div>;
    }

    return (
        <div className="main-content-quotes-table">
            <h2>Quotes</h2>
            <div className="table-container">
                <div className="search-bar-wrapper">
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearch}
                        />
                        {searchQuery ? (
                            <FaTimes className="clear-icon" onClick={() => setSearchQuery('')} />
                        ) : (
                            <FaSearch className="search-icon" />
                        )}
                    </div>
                </div>
                <div className="table-content-container">
                    <table>
                        <thead>
                            <tr>
                                {columnNames.map((col, index) => (
                                    col !== "Internal ID" && <th key={index}>{col}</th>
                                ))}
                                <th>Book Shipment</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((row, rowIndex) => {
                                const rowClass = getRowClass(rowIndex, row[0]);
                                row.className = rowClass;
                                return (
                                    <tr key={rowIndex} className={rowClass}>
                                        {row.map((cell, cellIndex) => (
                                            columnNames[cellIndex] !== "Internal ID" && <td key={cellIndex}>{cell}</td>
                                        ))}
                                        <td>
                                            <button onClick={() => handleBookShipment(row)}>Book Shipment</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {selectedQuote && (
                <BookShipmentDialog
                    isOpen={isDialogOpen}
                    onClose={handleCloseDialog}
                    quote={selectedQuote}
                    showAlert={handleShowAlert}
                    removeRowsWithSameSalesOrder={removeRowsWithSameSalesOrder}
                />
            )}
            <CustomAlert
                show={alert.show}
                handleClose={handleCloseAlert}
                title={alert.title}
                message={alert.message}
            />
        </div>
    );
};

export default QuotesTable;
