import axios from 'axios';
import React, { useState, useContext } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import './formStyles.css';
import { AuthContext } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import BACKEND_URL from './config';

const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const { setLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(
                `${BACKEND_URL}/api/login`,
                { username, password },
                { withCredentials: true }
            );
            if (response.data.success) {
                setLoggedIn(true);
                localStorage.setItem('token', response.data.token);
                navigate('/tms/home');
            } else {
                setError(response.data.message || 'Invalid username or password');
            }
        } catch (error) {
            if (error.response?.status === 429) {
                setError('Too many login attempts. Please try again later.');
            } else {
                setError('An error occurred during login. Please try again.');
            }
        }
    };

    return (
        <Container className="login-container">
            <h2>Login</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formUsername">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </Form.Group>
                {error && <div className="error-message">{error}</div>}
                <Button variant="primary" type="submit" className="submit-button">
                    Login
                </Button>
                <div className="forgot-password-link">
    <Link to="/tms/reset-password">Forgot Password?</Link>
</div>
            </Form>
        </Container>
    );
};

export default LoginForm;
