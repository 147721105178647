import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import { AuthContext } from './AuthContext'; // Import the AuthContext
import './styles.css'; // Import your CSS file

const Layout = ({ children, mainContentClass = "" }) => {
    const { loggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!loggedIn) {
            // Redirect to login page if not logged in
            navigate('/tms/login');
        } else if (location.pathname === '/tms') {
            // Redirect logged-in users visiting /tms to /tms/home
            navigate('/tms/home');
        }
    }, [loggedIn, navigate, location.pathname]);

    return (
        <div className="app-container">
            <Sidebar />
            <div className={`main-content ${mainContentClass}`}>
                {children}
            </div>
        </div>
    );
};

export default Layout;
