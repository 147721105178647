// src/components/Hero.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Hero = () => {
  return (
    <div
      className="hero-section fullscreen-section"
      style={{
        backgroundColor: '#0498FE', // Set the background color
        color: 'white', // Ensure text is still visible
       
        display: 'flex', // Center content
        alignItems: 'center', // Vertically center
        justifyContent: 'center', // Horizontally center
      }}
    >
      <Container>
        <Row>
          <Col className="text-center">
            <h1>Welcome to Freight Ops TMS</h1>
            <p>Your Partner in Smarter, Faster Freight Management</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
