import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import './styles.css'; // Ensure this is the correct path to your CSS file
import axios from 'axios';
import BACKEND_URL from './config'; // Ensure BACKEND_URL is correctly set up

const Home = () => {
    const navigate = useNavigate();
    const { loggedIn } = useContext(AuthContext);

    const [bookedShipments, setBookedShipments] = useState();
    const [shipmentsToBeBooked, setShipmentsToBeBooked] = useState();

    useEffect(() => {
        const fetchBookedShipments = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/api/home`, {
                    withCredentials: true,
                });
                setBookedShipments(response.data.count || 0); // Use the 'count' field from the API response
                setShipmentsToBeBooked(response.data.quotes || 0); 
            } catch (error) {
                console.error('Error fetching booked shipments:', error);
                setBookedShipments(0); // Default to 0 on error
            }
        };
    
        fetchBookedShipments();
    }, []);
    

    const handleNavigation = (path) => {
        if (loggedIn) {
            navigate(path);
        } else {
            navigate('/tms/login');
        }
    };

    return (
        <Container className="mt-5">
            <Row className="text-center">
                <Col>
                    <h1>Welcome to Freight Ops TMS</h1>
                </Col>
            </Row>
            {/* New row for the additional cards with custom styling */}
            <Row className="mt-4">
                <Col md={6}>
                    <Card className="mb-3 custom-card">
                        <Card.Body>
                            <Card.Title>Shipments Booked</Card.Title>
                            <h2>{bookedShipments}</h2>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="mb-3 custom-card">
                        <Card.Body>
                             <Card.Title>Number of Quotes</Card.Title>
                            <h2>{shipmentsToBeBooked}</h2>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/* Existing row for other cards without custom styling */}
            <Row className="mt-5">
                <Col md={4}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Manage Quotes</Card.Title>
                            <Card.Text>
                                Create, view, and manage your transportation quotes effortlessly.
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleNavigation('/quotes')}>Learn More</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Spot Quotes</Card.Title>
                            <Card.Text>
                                Get instant spot quotes for your urgent transportation needs.
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleNavigation('/spot-quotes')}>Get Spot Quotes</Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="mb-3">
                        <Card.Body>
                            <Card.Title>Contact Us</Card.Title>
                            <Card.Text>
                                Reach out to us for any queries or support. We are here to help you.
                            </Card.Text>
                            <Button variant="primary" onClick={() => handleNavigation('/contact')}>Contact Support</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Home;
